/*!
=========================================================
* Material Dashboard React - v1.7.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React  from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Spinner } from 'reactstrap';
import { user_info, user_info_up, isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import CopyToClipboardButton from 'components/CustomButtons/CopyToClipboardButton.jsx';
import jwt from "jwt-decode";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import PageWrapper from "components/PageWrapper/PageWrapper";
import TermsAndCondition from "components/TermsAndConditions/index";



import axios from 'axios';
const styles = {
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Raleway', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	containerother: {
		paddingRight: "25px",
		paddingLeft: "25px",
		marginRight: "auto",
		marginLeft: "auto"
	},
	cardstyle: {
		borderRadius: "2.2rem",
		"@media only screen and (max-width: 960px)": {
				marginTop: "80px"
		}
	},
	colorlink: {
		color: "#009df0"
	  }
};

//function UserProfile(props) {
class UserProfile extends React.Component {
	constructor(props) {
		super(props);
		var decodedtoken = "";
		let redirect = !isAuthenticated()

		if (isAuthenticated()) {
		  decodedtoken = jwt(localStorage.getItem( 'token' ));
		}
		this.state = {
			name: "",
			lastname: "",
			department: "",
			email: "",
			avatar: "",
			loading: false,
			loadingfirst: true,
			errorMessage: "",
			registrationLink : "",
			company: decodedtoken==="" ? "":decodedtoken.company,
			redirect
		};

		//console.log('Decoded Token', decodedtoken)

		this.toggle = this.toggle.bind(this);
	}
	async getuserinfo() {
		const usrurl = user_info;
		const token = localStorage.getItem('token');
		const authstr = 'Bearer ' + token;
		axios.get(usrurl, { headers: { Authorization: authstr } })
			.then(response => {
				this.setState({
					name: response.data.first_name,
					lastname: response.data.last_name,
					department: (typeof response.data.department!==undefined) ? response.data.department:"",
					email: response.data.email,
					loadingfirst: false,
					registrationLink: response.data.registration_link
				});
			})
			.catch(err => {
				this.setState({ loading: false, errorMessage:  err });
				this.setState({ loadingfirst: false });
			})
	}
	componentDidMount() {
		this.getuserinfo();
	}
	toggle() {
		const upddata = {
			email: this.state.email,
			first_name: this.state.name,
			last_name: this.state.lastname,
			department: this.state.department,
		};

		const token = localStorage.getItem('token');
		const authstr = 'Bearer ' + token;

		this.setState({ loading: true }, () => {
			axios.post(user_info_up, upddata, { headers: { Authorization: authstr } })
			.then(res => {
				console.log(res);
				this.setState({ loading: false });
			})
			.catch(err => {
				console.log(err);
				this.setState({ loading: false });
			})
		});
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			const currentPath = this.props.location.pathname;
			const redirectPath = "/login?redirect=" + currentPath;
			return <Redirect to= {redirectPath} />;
		}
	};

	render() {
		this.renderRedirect();
		const { classes, t } = this.props;
		const { loading } = this.state;
		return (
			<PageWrapper
				activeOverlay={this.state.loadingfirst}
			>
				{this.state.errorMessage==="" &&
				<div className={classes.containerother}>
					<GridContainer style={{color:"#3c4858"}}>
						<GridItem xs={12} sm={12} md={12}>
							<h2 style={{ marginTop: "1.5rem" }}>
								{t("User's profile")}
							</h2>
							<p>{t("The following displayes your personal data stored in the Incept Sustainability system")+":"}</p>
							<Card className={classes.cardstyle}>
								<CardBody style={{padding:"0 30px 30px 30px"}}>
									<GridContainer>
										{/*<GridItem xs={12} sm={12} md={5}>
											<CustomInput
												labelText="Username"
												id="username"
												value={this.state.username}
												formControlProps={{
													fullWidth: true,
													disabled: true
												}}
												onChange={e => this.setState({ username: e.target.value })}
											/>
											</GridItem>*/}
										<GridItem xs={12} sm={12} md={5}>
											<CustomInput
												labelText={t("Email address")}
												id="email-address"
												value={this.state.email}
												formControlProps={{
													fullWidth: true,
													disabled: true
												}}
												onChange={e => this.setState({ email: e.target.value })}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={6}>
											<CustomInput
												labelText={t("First Name")}
												id="first-name"
												value={this.state.name}
												formControlProps={{
													fullWidth: true,
													disabled: true
												}}
												onChange={e => this.setState({ name: e.target.value })}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={6}>
											<CustomInput
												labelText={t("Last Name")}
												id="last-name"
												value={this.state.lastname}
												formControlProps={{
													fullWidth: true,
													disabled: true
												}}
												onChange={e => this.setState({ lastname: e.target.value })}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<CustomInput
												labelText={t("Department")}
												id="postal-code"
												value={this.state.department}
												formControlProps={{
													fullWidth: true,
													disabled: true
												}}
												onChange={e => this.setState({ department: e.target.value })}
											/>
										</GridItem>
									</GridContainer>
									<div style={{marginTop:"30px"}}>
										<p>{t("ContactMessage")}
										<a className={classes.colorlink} href = "mailto:inceptsustainability@ramboll.com?subject = More Information">
											 {" "}inceptsustainability@ramboll.com</a></p>
									</div>
									{(this.state.company!=="Ramboll") && this.state.registrationLink!=="" &&
  									<div style={{marginTop:"30px"}}>
  										<h3>{t("Sign up link for employees")}</h3>
  										<p><a href={this.state.registrationLink}>{this.state.registrationLink}</a>
  										<CopyToClipboardButton
  										toolTipText={t("Copy link to clipboard")}
  										stringToShare={this.state.registrationLink}/>
  										</p>
  									</div>
									}
  
								<TermsAndCondition
									companyId = {this.props.databackend.company_id ?? -1}/> 
								</CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12} md={2}  >
										{loading && <Spinner style={{ marginTop: '15px' }} color="info" />}
									</GridItem>
								</GridContainer>
							</Card>
						</GridItem>
						{/*<GridItem xs={12} sm={12} md={4}>
							<Card profile className={classes.cardstyle}>

								<CardBody profile>
									<h6 className={classes.cardCategory}>{this.state.department}</h6>
									<p className={classes.description}>
									</p>
								</CardBody>
							</Card>
						</GridItem>*/}
					</GridContainer>
				</div>
				}
				{this.state.errorMessage!=="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
					<h2>Well, this was unexpected. </h2>
					<p>
						Sorry, this is not working properly. We now know about
						it and are working to fix it.
					</p>
					<p>In the meantime, here is what it can be done:
						<ul>
							<li>Refresh the page (sometimes this helps).</li>
							<li>Make sure you have internet connection.</li>
							<li>Try again in 30 minutes.</li>
							<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
						</ul>
					</p>
					</div>
				</div>
				}
			</PageWrapper>
		);
		// return (<div></div>);
	}
}

UserProfile.propTypes = {
	classes: PropTypes.object
};

export default withTranslation()(withStyles(styles)(UserProfile));
