/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: Analytics_New
 */
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Grid from "@mui/material/Grid";
import Modal from "react-bootstrap/Modal";
import {Link } from "react-router-dom";

import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,ourLightGreen,
    ourBlue, isAuthenticated, get_company_user_info,get_onb_email,save_data
} from "assets/jss/incept-sustainability-variables.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import ActionButton from 'components/CustomButtons/ActionButton';

import CommentsCard from "./Components/CommentsCard";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';

//images
import SustainabilityLevelIcon from "assets/img/SustainabilityLevelIcon.png";
//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { useMediaQuery } from 'react-responsive';
import { useRef} from "react";
import ReactHtmlParser from 'react-html-parser';
import "react-circular-progressbar/dist/styles.css";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
import classNames from "classnames";
import icon4 from "assets/img/icon4.png";
import { IconButton } from "@mui/material";
import icon7 from "assets/img/icon7.png";
import icon7disabled from "assets/img/icon7-disabled.png";
import Typography from "@mui/material/Typography";
import {default as CustomModal}  from "components/Modal/Modal.jsx";
import emailnot from "assets/img/email_not.png";
//Translate
import Form from "react-bootstrap/Form";
import { FormGroup,  Input } from "reactstrap";
import loadinggif from "assets/img/loading.gif";
import { withTranslation, Trans,useTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import jwt from "jwt-decode";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import axios from "axios";
import { ourOrange } from "assets/jss/incept-sustainability-variables";
import SustainabilityLevelBar from "components/CustomProgressBar/SustainabilityLevelBar";
import { ourOrange25 } from "assets/jss/incept-sustainability-variables";
import { ourOrange60 } from "assets/jss/incept-sustainability-variables";

import { fetchGuides } from "views/Guides/api/fetchGuides";
import ListModules from "views/Guides/ListModules";
import PageWrapper from "components/PageWrapper/PageWrapper";

const useStyles = (theme) => ({
    smallButton: ({ color, textColor }) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    })
});


function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 1024px)` });
        const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
		const isSmallLaptop = useMediaQuery({ query: '(max-width: 1440px)'})
        return <Component {...props} isMobile={mobile} isTablet={isTablet} isSmallLaptop={isSmallLaptop} />;
    }
}


function Dashboard_employee(props) {
    const { classes, username, content } = props;
    var decodedtoken = "";
    var redlog = false;

	


	let totalModuleCompletionSize;
	let moduleCompletionSize;

	switch(true) {
		case props.isMobile:
			totalModuleCompletionSize = "50px";
			moduleCompletionSize = "18px";
			break;
		case props.isTablet:
			totalModuleCompletionSize = "30px";
			moduleCompletionSize = "90%";
			break;
		case props.isSmallLaptop:
			totalModuleCompletionSize = "35px";
			moduleCompletionSize = "11px";
			break;
		default:
			totalModuleCompletionSize = "50px";
			moduleCompletionSize = "18px";
			break;
	}
    if (!isAuthenticated()) {
        redlog = true;
    }
    else
        decodedtoken = jwt(localStorage.getItem("token"));
    const hasEverLoggedIn = JSON.parse(localStorage.getItem('hasEverLoggedIn')??'false');
	const userid = (decodedtoken!="") ? decodedtoken.userid:"";
    localStorage.setItem('hasEverLoggedIn',JSON.stringify(true));
    const [company, setCompany] = useState((decodedtoken==="") ? "":decodedtoken.company);   
    const [errorMessage, seterrorMessage] = useState("");
    const [allideas, setallideas] = useState("");
    const [notError, setnotError] = useState(false);
    const [sendNotification, setsendNotification] = useState(false);
    const [seemore1, setseeMore1] = useState(false);
    const [seemore2, setseeMore2] = useState(false);
    const [showReminderModal, setshowReminderModal] = useState(false);
    const [showVideoModal, setshowVideoModal] = useState(false);
    const [showIdeasModal, setshowIdeasModal] = useState(false);
	const [showNewsModal, setshowNewsModal] = useState(false);
	const [limitText, setlimitText] = useState(false);
    const [sendingNotEmail, setsendingNotEmail] = useState(false);
    const [addNewidea, setaddNewidea] = useState(false);
    const [showideabox, setshowideabox] = useState(true);
    const [loadingfirst, setloadingfirst] = useState(true);
    const [loading, setloading] = useState(false);
    const [showFirstLoginModal, setshowFirstLoginModal] = useState(!hasEverLoggedIn);
    const [valueDD, setvalueDD] = useState(0);
    const [dataBackend, setdataBackend] = useState({
        ideas: "",
        ideasall: [],
    });
    const [labels, setlabels] = useState([]);
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);

	const [listModules, setListModules] = useState([]);
	const [ideasComments, setIdeasComments] = useState([]);
	const [modulesToStart, setModulesToStart] = useState([]);
	const [startModuleLink, setStartModuleLink] = useState("");
	const [startModuleTitle, setStartModuleTitle] = useState("");
	

	const button_word = (started, completed, t) => {		
		if (parseInt(started)===1 && parseInt(completed)===1) {
			return t("Recap");
		}
		else if (parseInt(started)===1) {
			return t("Continue");
		}
		else {
			return t("Start");
		}
	}

    useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		const fetchData = async () => {
			const token = localStorage.getItem("token");
			const useLang = i18n.language;
            try {
                const result = await fetchGuides(token, null, useLang); // It threw an error because fetchGuides accepts 3 args and only 2 were passed
				setListModules(result.all_modules);
            } catch (error) {
                console.error("Error fetching guides:", error);
            }
        };

       
		if (loadingfirst) {
			getgeneralinfo();
			fetchData();
		}
	  }, []);

	//Get ideas from backend
	const getgeneralinfo = async () => {
        setloadingfirst(true);
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem('token');
		const authstr = 'Bearer ' + token;
		const useLang = i18n.language;	
		let url = get_company_user_info + "dashboard?wpml_language=" + useLang;
		axios.get(url, { headers: { Authorization: authstr } })
			.then(response => {
                console.log(response);
				var stateCopy = {};
				stateCopy["ideas"] = { "idea1": response.data.idea1, "idea2": response.data.idea2 };
				stateCopy["ideasall"] = response.data.ideasall;
                setdataBackend(stateCopy);
                // console.log(stateCopy);
                setlabels(response.data.labels);
                setloadingfirst(false);
				setIdeasComments(response.data.ideasComments);
				let modules = response.data.modules;
				let started = Object.entries(response.data.guideStarted);
				for (const [guideid, isstarted] of started) {
					const index = modules.findIndex(module => module.id === guideid);
					if (index !== -1)
						modules[index].started = isstarted;
				}
				let notStartedOrCompletedModule = modules.find(module => module.started === -1 || module.completed === 0);
				let nextModuleIndex = notStartedOrCompletedModule ? notStartedOrCompletedModule.id: -1;
				if (nextModuleIndex !== -1) {
					setStartModuleLink("/guides/" + nextModuleIndex + "/0/act/");
					setStartModuleTitle(notStartedOrCompletedModule.name);
				}				
				setModulesToStart(modules);
				
			})
			.catch(err => {
				//this.error_ocurred("Error API "+n_comp_pck+" "+err);
                console.log(err);
				let errorDes = "Error API "+(save_data+ "dashboard ")+err;
				//log.error(errorDes);
                seterrorMessage(err);
                setloadingfirst(false);
			})
	}

    const showHideModal = (id) => {
		try {
			if (id === 1) {
                setshowVideoModal(!showVideoModal);
			} 
			else if (id===3) {
                setshowIdeasModal(!showIdeasModal);
			}
			else if (id===4) {
                setaddNewidea(true);
			}
			else if (id===55) {
				sendEmailOnboard();
                setsendNotification(!sendNotification);
				trackEvent('Send reminder in Mobile');
			}
			else {
                setshowReminderModal(!showReminderModal);
			}
		}
		catch(error) {
			throw new Error("Component: Dashboard - showHideModal");
		}
 	};

    const sendEmailOnboard = () => {
		if (!isAuthenticated()) {
			props.isExpired();
		}
        setloading(true);
		const formdata = {
			test: true,
		};

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
			axios
				.post(get_onb_email, formdata, { headers: { Authorization: authstr } })
				.then((res) => {
                    setsendingNotEmail(true);
                    setnotError(false);
				})
				.catch((err) => {
					if (err.response!==undefined)
						console.log(err.response.data.message);
					else 
						console.log(err);
					alert("There has been an error. Please try again.");
                    setsendNotification(false);
                    setnotError(true);
				});
	};

    const seemore = (e,id) => {
		try {
			e.preventDefault();
			if (id==="id1")
                setseeMore1(!seemore1);
			else 
                setseeMore1(!seemore2);
		}
		catch(error) {
			throw new Error("Component: Dashboard - seemore");
		}
	}

    const handleHideFirstLoginModal = () => {
		try {
            setshowFirstLoginModal(false);
		}
		catch(error) {
			throw new Error("Component: Dashboard - handleHideFirstLoginModal");
		}
	}

    const handleChange = (e) => {

		try {
			const limitText = e.target.value;
			if(limitText.length<=10){
				setlimitText(true);
			}
			else{
				setlimitText(false);
			}
			const allideas = e.target.value;
            setallideas(allideas);
		}
		catch(error) {
			throw new Error("Component: Dashboard - handleChange");
		}
	}

    const onChangeDD = (event) => {
		const answerIndexSelected = parseInt(event.target.value); 
        setvalueDD(answerIndexSelected);
	}

    const handleChange2 = (e) => {
		try {
			if(limitText === false){
			if(allideas==="") {
				alert(t("Text field cannot be empty"));
				return;
			}
			saveideas();
            setshowideabox(false);
            setallideas("");

			let timeoutIdeas = setTimeout( () => {
                setshowideabox(true);
				setaddNewidea(false);
			}, 2000);
		}
	
	else{
		setlimitText(true);
	}
}
		catch(error) {
			throw new Error("Component: Dashboard - saveideas ");
			console.log("Error: ",error);
		}
	}

    const saveideas = () => {
		if(allideas==="") {
			alert(t("Text field cannot be empty"));
			return;
		}

		if (!isAuthenticated()) {
			props.isExpired();
		}

		var arraytosend = [];
		var aux2 = { type: "Ideas", content: allideas, label: valueDD };
		arraytosend["cIdeas"] = aux2;
		var takstosave = [];
		takstosave.push("Ideas"); 


		//data to send
		const insdata = {
			userid: userid,
			tosave: JSON.stringify({ ...arraytosend }),
			guide: 0,
			step: "",
			postid: 0,
			task: 0,
			ntasks: takstosave,
			ifn: 0
		};

		const token = localStorage.getItem("token"); //token needed
		const authstr = "Bearer " + token;
		//post
			axios
			.post(save_data+"0/answers", insdata, { headers: { Authorization: authstr } })
			.then((res) => {
                setallideas(allideas);
				getgeneralinfo();
			})
			.catch((err) => {
				//this.error_ocurred("Error API "+save_data+" "+err);
				let errorDes = "Error API "+(save_data+"0/answers ")+err;
				//log.error(errorDes);
				console.log(err);
			});
	}

    return (
        <PageWrapper
            activeOverlay={loadingfirst}
        >
            {errorMessage==="" &&
				<div className={classes.containerother} style={{marginBottom:"20px"}}>
					<div className={classes.gridWrappper}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<Card className={classes.welcomeGrid} style={{paddingBottom:0,paddingLeft:0}}>
									<CardBody
										style={{ padding: "1rem 1rem 0 0" }}
									>
										<h1 style={{ margin: "0 0 5px", fontWeight: "400" }}>
                      						<strong>{t('Welcome username', {username:props.databackend.username, defaultValue:"Welcome {{username}}"})}</strong>
										</h1>
									</CardBody>
								</Card>
							</GridItem>
							<GridContainer style={{width:"100%"}}>
								{/*Maintenance <GridItem xs={12} sm={12} md={9}>
									<p style={{fontSize:"0.9rem",paddingLeft:"1px",backgroundColor:"#FFB899"}}>
									{"On 29 January 2023 from 10:00 CET to 16:00 CET, this portal will be briefly unavailable due to a software update."}</p>
								</GridItem>*/}
								<GridItem xs={12} sm={12} md={7}>
										<p style={{paddingBottom:"20px",fontSize:"0.9rem",paddingLeft:"1px"}}>
											<span>
												{props.databackend.engage_message}
											</span>
										</p>
								</GridItem>
							</GridContainer>
							<GridItem xs={12} sm={12} md={12} style={{display: props.isMobile ? '' : 'flex'}}>
								{props.isMobile && (company==="Ramboll") && <GridItem xs={12} sm={12} md={12} space={props.isMobile ? '' : 0} >
									<Card className={classes.DFDSSection}>
										<CardBody>
										{sendingNotEmail && !notError && 
										<p style={{color:ourDarkGreen,fontStyle:"italic",textAlign:"center",fontSize:"1rem"}}>
											<b>Easy access link already sent!</b></p>}
										{t("Thank you for joining Ramboll's Sustainability Learning Universe. "
										+"You can take the training from your mobile, but you will get ")}
										<b>{t("the best experience on your laptop. ")}</b>
											<ActionButton size="large"
											onClick={(e) => showHideModal(55)}>
												{t('Click here')}
												<img src={emailnot} width="30" style={{marginLeft:"10px"}}/>
											</ActionButton>
										<b>{t("for an easy access link sent to your inbox")}</b>
										{t(" so you can open the training the next time you are on your computer.")}
										</CardBody>
									</Card>
								</GridItem>}
								<GridItem xs={12} sm={12} md={6.5} space={props.isMobile ? '' : 0}>
									<Card className={classes.DFDSSection} style={{height: '96%'}}>
										<CardBody className={classes.moduleCardBody2} style={{fontSize: '1rem'}}>
										{props.role==="Employee" && ReactHtmlParser(props.databackend.message)}
										{(props.role==="Learner Manager" || props.role==="Custom Learner Manager") && ReactHtmlParser(props.databackend.message_learner_manager)}
										</CardBody>
									</Card>
								</GridItem>

								<GridItem xs={12} sm={12} md={5.5}>
									<div>
										<Card className={classes.moduleCard}>
											<CardBody className={classes.moduleCardBody2}>
												<div style={{display: "table-cell", verticalAlign:"middle"}}>
													{/*<img
														id="1"
														className={classes.cardImg}
														style={{ width: "100%" }}
														src={videobackgr}
														alt="video background"
														onClick={(e) => this.showHideModal(1)}
													/>*/}
													<div>
													{props.role==="Employee" && ReactHtmlParser(props.databackend.videoE)}
													{(props.role==="Learner Manager"  || props.role==="Custom Learner Manager") && ReactHtmlParser(props.databackend.videoM)}
													</div> 
												</div>
											</CardBody>
										</Card>
									</div>
									{/*--------------------------- This is the news card! ---------------------- */}
									<div>
										<Card className={classes.moduleCard}  >
											<CardBody className={classes.moduleCardBody2}>
												<div style={{display: "table-cell", verticalAlign:"middle"}} >
													<h5 style={{fontWeight: '100', color: 'black'}}>
														{t("News")}
													</h5>
													<Card style={{backgroundColor: '#F3F3F3', marginBottom: 0}}>
														<h5 style={{margin: props.isMobile ? '' : '1rem 2.5rem 0 1.5rem', 
																padding: props.isMobile ? "1rem" : ""}}>
																	{props.databackend.news!==undefined && t(`${props.databackend.news.title}`)}
														</h5>
														<div style={{
																margin: props.isMobile ? '' :  '1rem 2.5rem 0 1.5rem',
																padding: props.isMobile ? "1rem" : "", 
																wordWrap: "break-word"}}
														>
															{props.databackend.news!==undefined && props.databackend.news.description!=="" && ReactHtmlParser(props.databackend.news.description.substring(0, 300) + ".....")}
															{props.databackend.news!==undefined && props.databackend.news.description==="" && <p style={{textAlign: 'center', fontStyle: 'italic'}}>{t('No news to display')}</p>}
														</div>
														<div style={{display: 'flex', flexDirection: props.isMobile ? 'column' : '', justifyContent: props.isMobile ? 'center' : 'space-between', alignItems: 'center', maringBottom: "3px"}}>
															<p style={{margin: props.isMobile ? '' : '0 0 1rem 1.5rem', fontWeight: 'bold', color: '#D9D9D9'}} >{props.databackend.news!==undefined && t(`${props.databackend.news.date}`)}</p>
															{props.databackend.news!==undefined && <Link to={{  pathname:props.databackend.news.url}} target="_blank" style={{textAlign: "right",marginRight: props.isMobile ? '' : '2.5rem', width: props.isMobile ? 'auto' : '40%'}} >
																<ActionButton style={{fontSize: '15px', width:"auto"}} backGround={false}
																>
																	{t("Read more")}
																</ActionButton>
															</Link>}
														</div>
													</Card>
												</div>
											</CardBody>
										</Card>
										{/* <GridItem xs={12} sm={12} md={5}>
												<div className={classes.imgtip}>
													<img alt="" src={props.databackend.tip.tipimg} className="responsive" 
													style={{marginBottom:"10px"}} />
												</div>
											</GridItem> */}
										{/*--------------------------- This is the news card END! ---------------------- */}
									</div>
								</GridItem>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}  style={{display: 'flex', flexDirection: props.isMobile ? 'column-reverse' : 'row'}}>
								{/* <GridItem xs={12} sm={12} md={6.5} space={props.isMobile ? '' : 0} >
									<Card className={classes.moduleCard} style={{height:"93%"}}>
										<CardBody className={classes.moduleCardBody2+" "+classes.compmodules} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
											<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
												<img 
												style={{
													borderRadius: "50%", 
													transform: 'scale(1.2)', 
													marginBottom: '10px'
												}}
												src={SustainabilityLevelIcon}
												height={35} width={35} 
												alt="Achievement Level icon" />
												<h5 style={{ fontWeight: "bold", display: "table-row", marginLeft: '10px' }}>Your sustainability level</h5>
											</div>
											<div>
												<h6 style={{fontSize: '2rem', lineHeight: '0.7', paddingBottom: '1rem'}}>LEVEL {props.databackend.sustainLevel}</h6> 
												{<p style={{fontWeight: '900', fontSize: '1rem', fontFamily: "gotham-font-book", lineHeight: '1',}}>
													To reach next level: 
														<span style={{fontWeight: '100', fontFamily: "gotham-font-book", marginLeft: '5px'}}>
															complete {props.databackend.sustainLevelLeft} course(s)
														</span>
												</p>}
											</div>

											{/* <SustainabilityLevelBar level={sustainLevel}/> 
												{props.databackend.sustainLevel > 0 && props.databackend.totalmod === props.databackend.complemod &&
												<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '30px', justifySelf: 'end', marginBottom: "20px"}}>
												<p style={{fontSize: '0.95rem', color: ourOrange60, marginBottom: '0', fontWeight: 'bold'}}> 
												 {modulesToStart.length > 0 ? startModuleTitle : 'Next: Loading.....'} </p>
													<Link to={"/admin/guides"}>
														<ActionButton 
															style={{fontSize: '15px' ,width:"auto", marginLeft: props.isMobile ? "0" : "", marginBottom: '0'}}
															className={classes.button2}
														>
															{t('See all modules')}
														</ActionButton>
													</Link>
												</div>
												}
												{props.databackend.totalmod !== props.databackend.complemod &&
												<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '30px', justifySelf: 'end', marginBottom: "20px"}}>
												<p style={{fontSize: '0.95rem', color: ourOrange60, marginBottom: '0', fontWeight: 'bold'}}> 
												Next: {modulesToStart.length > 0 ? startModuleTitle : ' Loading.....'} </p>
													<Link to={startModuleLink}>
														<ActionButton 
															style={{fontSize: '15px' ,width:"auto", marginLeft: props.isMobile ? "0" : "", marginBottom: '0'}}
															className={classes.button2}
														>
															{t('Go to the next module')}
														</ActionButton>
													</Link>
												</div>
												}
										</CardBody>
									</Card>
								</GridItem> */}
								<GridItem xs={12} sm={12} md={6.5} style={{ marginTop: "0", display: 'flex', alignItems: 'center' }} space={props.isMobile ? '' : 0}>
								<Card className={classes.moduleCard} style={{height:"95%"}}>
								<CardBody className={`${classes.moduleCardBody2} ${classes.compmodules}`} style={{display: 'flex', justifyContent: 'center', transform: `${!props.isMobile ? 'scale(1.1)' : ''}` }}>
											<h5 style={{ fontWeight: "400",display: "table-row"  }}>
											</h5>
											<div style={{verticalAlign: "middle", display: props.isMobile | props.isTablet | props.isSmallLaptop ? '' : "flex", alignItems: 'center', marginLeft: props.isMobile ? '' : '20px'}}>
												<CircularProgressbarWithChildren
													value={props.databackend.completedm}
													strokeWidth={12}
													styles={buildStyles({
														strokeLinecap: "butt",
														pathColor: ourGreen,
														trailColor: ourLightGreen,
														pathTransition: "none"
													})}
													/*className={classes.compmod}*/
												>
													{
														<div style={props.isMobile ? {}:{maxWidth:"10vw"}}>
														<div className={classes.data}
														style={{
															textAlign: "center",
															fontSize: `${totalModuleCompletionSize}`,
															color: ourBlue,
															fontFamily: "gotham-font-bold",
															fontWeight: "bolder",
															marginBottom: "10px",
															lineHeight: "normal",
														}}
														>
															
														{props.databackend.complemod+"/"}
														{props.databackend.totalmod}
														</div>

														<p 
														style={{
															textAlign: "center",
															fontSize: `${moduleCompletionSize}`,
															color: ourBlue,
															fontFamily: "gotham-font-book",
															fontWeight: "1000",
															}}>

															{t('Modules completed')}
														</p>
													</div>
													}
												</CircularProgressbarWithChildren>

											{/* This following secction will be fethcing the next module for user, and print the module label and button linking towards it. 
												1. We need to create a method which fetches the label name, and populates the paragraph with that name
												2. We need a method which directs user when clicking actionbutton to start inteded module */}
												<div style={{width: props.isMobile | props.isTablet | props.isSmallLaptop ? 'auto' : '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
													{/*<p 
														style={{
															fontSize: "90%",
															color: ourOrange,
															fontStyle: "italic"
														}}
													>
														Next: Speaking to clients about sustainability
													</p>*/}
													<div style={{display: 'flex', flexFlow: 'column wrap', marginTop: props.isMobile || props.isTablet || props.isSmallLaptop ? "2rem" : ""}}>
														<div style={{display: 'flex', alignItems: 'center'}}>
															<div style={{backgroundColor: ourGreen, borderRadius: '50%', width: '40px', height: '40px'}}></div>
															<p style={{marginLeft: '10px', marginTop: '15px', display: 'flex', alignItems: 'center'}}>{t("Completed")}</p>
														</div>
														<div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
															<div style={{backgroundColor: ourLightGreen, borderRadius: '50%', width: '40px', height: '40px'}}></div>
															<p style={{marginLeft: '10px', marginTop: '15px', display: 'flex', alignItems: 'center'}}>{t("Started")}</p>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</GridItem>
								<GridItem xs={12} sm={12} md={5.5} style={{ marginTop: "0" }}>
									<Card className={classes.DFDSSection} style={{height: '95%', verticalAlign: 'center'}} >
									<CardBody className={classes.moduleCardBody} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
  									
											<div style={{marginTop: "3rem"}}>
												{ 
													ideasComments.length > 0 
													? ideasComments.slice(0,2).map((idea, index) => (
														<CommentsCard key={index} date={idea.date} isMobile={props.isMobile} commentData={idea.text} />
													))
													: <p style={{textAlign: 'center', fontStyle: 'italic'}}>{t('Your ideas and comments will be displayed here')}</p>
												}
											</div>
											<div className={classes.buttonSection} style={{textAlign: props.isMobile ? 'center' : 'right', marginBottom: "-8px"}} >
												<ActionButton
													style={{width:"auto", marginLeft: props.isMobile ? "0" : ""}} 
													onClick={(e) => showHideModal(3)}
													// className={classes.button2}
													backGround={false} bordered={"true"} lighten={false}
													
												>
													{t('See all')}
												</ActionButton>
												<ActionButton 
													style={{width:"auto", fontSize: "15px", marginLeft: props.isMobile ? "0" : "", marginRight: '20px'}}
													onClick={(e) => showHideModal(4)}
													className={classes.button2}
												>
													{t('Add new comment or idea')}
												</ActionButton>
											</div>
										</CardBody>
									</Card>
								</GridItem>
							</GridItem>

							<GridItem xs={12} sm={12} md={12} style={{display: props.isMobile ? '' : 'flex'}}>
							<GridItem xs={12} sm={12} md={6.5} space={!props.isMobile ? 0 : ''} style={{ marginTop: "0" }}>

								<ListModules 
									list_modules={modulesToStart} 
									t={t} 
									startModule={"/0"} 
									type={"/act/"} 
									button_word={button_word}
									iskb={false}
									Dashboard 
									isMobile={!!props.isMobile}
								/>

							</GridItem>

								
							</GridItem>
						</GridContainer>
						<CustomModal
							show={false}
							onHide={handleHideFirstLoginModal}
						>
							<LoadingOverlay
								styles={{
									overlay:(base) => ({
										...base,
										backgroundColor:'transparent'
									})
								}}
								active={!username}
								spinner={<BounceLoader />}
							>
								<div style={{minHeight:'100px'}}>
									{username && <h1>{t('Welcome')} {username}</h1>}
									{username && 
										<video
											src={video1}
											width="100%"
											controls
											className={classes.modalVideo}>
											<source src={video1} type="video/mp4" />
										</video>
									}
								</div>
							</LoadingOverlay>
						</CustomModal>
						<Modal
							id="1"
							show={showVideoModal}
							onHide={() => setshowVideoModal(false)}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							
							</Modal.Body>
						</Modal>
						<Modal
							id="3"
							show={showIdeasModal}
							onHide={() => setshowIdeasModal(false)}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton
                                onClick={() => setshowIdeasModal(false)}
                                className={classes.modalCancelButton}
                                size="large">
								<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{margin:"30px", maxHeight:"500px", padding: "10px", overflowY:"auto", overflowWrap: "break-word", whiteSpace: "pre-wrap"}}>
								<h5 style={{fontWeight: "400"}}>
									<strong>{t('My comments and ideas')}</strong>
								</h5>
								{(dataBackend.ideasall!==undefined) && dataBackend.ideasall.map((value, index) => {
									return ( 
										<div key={"ideasdashboard"+index} className={classes.commentsContainer}>
											<img alt="" className={classes.commentsIcon} src={icon4} />
											<Typography
												component="p"
												variant="body1"
												style={{ textAlign: "justify" }}
											>
												{value}
											</Typography>
										</div>
									)})
								}
							</div>
							</Modal.Body>
						</Modal>
						<Modal
							id="4"
							show={addNewidea}
							onHide={() => setaddNewidea(false)}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton
                                onClick={() => setaddNewidea(false)}
                                className={classes.modalCancelButton}
                                size="large">
								<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
							{showideabox && <h5 style={{ fontWeight: "bold" }}>{t('Add new comment or idea')}</h5>}								<div id="ideabox"
								className={showideabox ? classes.showElement:classes.hideElement}>
									<Form>
									<Form.Control 
									as="textarea" 
									onChange={(e) => handleChange(e)}
									rows="8" 
									value={allideas}
									className={classes.textarea1} />
									{limitText === true? <p style={{color:"red"}}>{t("Please submit a valid idea (a minimum of 10 characters)")}</p>:""}
									{(labels !== undefined && labels.length>0) && 
									<Grid container className={classes.categorygrid}>
										<Grid item xs={12} md={8} className={classes.rightlabel}>
											<p style={{marginBottom:"0"}}>{t("Please select the category")+":"}</p>
										</Grid>
										<Grid item xs={12} md={4}>
											<DropDown
												value={valueDD}
												color="black"
												paddingShort={true}
												onChange={(event) => onChangeDD(event)}
											>
												{labels.map(
													(item) => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
												)}
												<MenuItem value={0}>
													<em>Other</em>
												</MenuItem>
											</DropDown>
										</Grid>
									</Grid>}
									<p className={classes.footertext}>
									{t('no personal data',
										{
											defaultValue: 'Please keep your submission anonymous, do not write any personal data in your submission.'})}
									</p>
									<ActionButton 
										onClick={(e) => handleChange2(e)}
										style={{ float: "right" }}
										className={classes.submitButton}>
										{t('Submit')}
									</ActionButton>
									</Form>
								</div>
								<div style={{margin:"30px 0 60px 0", textAlign:"center", position: "relative",zIndex:"9999"}}
									className={"responsive "+(!showideabox ? classes.showElement:classes.hideElement)}>
									<h2 style={{color:ourGreen}}>{t('Thank you!')} </h2>
									<h3>{t('Your comment has been received')+"."}</h3>
								</div>
							</div>
							</Modal.Body>
						</Modal>
						<Modal
							id="5"
							show={sendNotification}
							onHide={() => setsendNotification(false)}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
							<IconButton
                                onClick={() => (function() {setsendingNotEmail(false);setsendNotification(false);})}
                                className={classes.modalCancelButton}
                                disabled={!sendingNotEmail}
                                size="large">
								<img
								src={!sendingNotEmail ? icon7disabled:icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							{!sendingNotEmail && <div style={{ padding: "2.5rem" }}>
							<p><b>{t("Sending easy access link to your e-mail inbox")+"..."}</b></p>
							<img
								className="responsive"
								src={loadinggif}
								width="25"
								alt=""
								style={{ marginBottom: "20px" }}
							/>
							</div>}
							{sendingNotEmail && <div style={{ padding: "2.5rem" }}>
							<p><b>{t("E-mail sent!")}</b></p>
							<img
								src={checkmark}
								style={{
									width:"30px",
									visibility: (typeof datasav !== "undefined") ? "visible":"hidden"
								}}
								className={classes.checkmark}
								alt="Completed section"
								title="Completed section"
							/>
							</div>}
							</Modal.Body>
						</Modal>
					</div>
				</div>
				}
				{errorMessage!=="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
					<h2>Well, this was unexpected. </h2>
					<p>
						Sorry, this is not working properly. We now know about
						it and are working to fix it.
					</p>
					<p>In the meantime, here is what it can be done:
						<ul>
							<li>Refresh the page (sometimes this helps).</li>
							<li>Make sure you have internet connection.</li>
							<li>Try again in 30 minutes.</li>
							<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
						</ul>
					</p>
					</div>
				</div>
				}

        </PageWrapper>
    );

}


export default withTranslation()(withMyHook(withStyles(dashboardStyle)(Dashboard_employee)));

